import { Card, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import BigNumber from "bignumber.js";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { apiClient } from "../../api";
import { useAppDispatch } from "../../app/hooks";
import { getNav } from "../../features/nav/navActions";
import { ITransactionDetail } from "../../interfaces";
import { numberWithCommas } from "../../utils";
import InvestReturnChart from "./InvestReturnChart";


const TransactionList: React.FC = () => {
  const dispatch = useAppDispatch();
  const [nav, setNav] = useState<number>(0);
  const [transactionList, setTransactionList] = useState<ITransactionDetail[]>(
    []
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchNav = async () => {
      try {
        const navValue = await dispatch(getNav());
        setNav(navValue.payload);
      } catch (error) {
        console.error("Failed to fetch nav:", error);
      }
    };
    getTransactions();

    fetchNav();
  }, [dispatch]);

  const getTransactions = async () => {
    setLoading(true);
    try {
      const { data } = await apiClient.get(
        `/transaction-detail`
      );
      setTransactionList(data.reverse());
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const calculateTotal = (key: keyof ITransactionDetail) => {
    let total = 0;
    transactionList.forEach((item) => {
      if (typeof item[key] === "number") {
        total += item[key] as number;
      }
    });
    return total;
  };

  const footerContent = (
    <div className="flex font-bold">
      <div className="w-[23%] text-left">Нийлбэр дүн: </div>
      <div className="w-[18%] text-left">
        {numberWithCommas(calculateTotal("salaryAmount"))}₮
      </div>
      <div className="w-[19%] text-left">
        {numberWithCommas(calculateTotal("companyAmount"))}₮
      </div>
      <div className="w-[24%] text-left">
      </div>
      <div className="w-[13%] text-left">
      </div>
    </div>
  );

  const columns: ColumnsType<ITransactionDetail> = [
    {
      title: "№",
      render: (_: any, __: any, index: number) => index + 1,
      width: "3%",
    },
    {
      title: "Үүсгэсэн огноо",
      dataIndex: "confirmedAt",
      render: (data: string, record) => (
        <a className="transaction_info">
          {dayjs(data).format("YYYY-MM-DD HH:mm")}
        </a>
      ),
      width: "15%",
    },
    {
      title: "Ажилтнаас",
      dataIndex: "salaryAmount",
      render: (_, record) => {
        if (record.employeeSecurities < 0) {
          const outCome = record.employeeSecurities * record.nav;
          return numberWithCommas(outCome);
        } else {
          return numberWithCommas(record.salaryAmount);
        }
      },
      width: "15%",
    },
    {
      title: "Компаниас",
      dataIndex: "companyAmount",
      render: (_, record) => {
        if (record.employeeSecurities < 0) {
          const outCome = record.poolSecurity * record.nav;
          return numberWithCommas(outCome);
        } else {
          return numberWithCommas(record.companyAmount);
        }
      },
      width: "15%",
    },
    {
      title: "Барьсан хугацааны өгөөж",
      render: (_, record) => {
        if (record.employeeSecurities > 0) {
          const currentAmount = record.employeeSecurities * nav;
          const startAmount = record.employeeSecurities * record.nav;
          const sub = currentAmount - startAmount;
          const holdingPeriodYield = (sub / startAmount) * 100;
          return <span>{holdingPeriodYield.toFixed(2)}%</span>;
        }
      },
      width: "20%",
    },
    {
      title: "Жил рүү хөрвүүлсэн өгөөж",
      render: (_, record) => {
        if (record.employeeSecurities > 0) {
          const currentAmount = record.employeeSecurities * nav;
          const startAmount = record.employeeSecurities * record.nav;
          const sub = currentAmount - startAmount;
          const holdingPeriodYield = sub / startAmount;

          const confirmedAt = dayjs(record.confirmedAt);
          const currentDate = dayjs();
          const daysDifference = currentDate.diff(confirmedAt, "day");
          const pow = 365 / daysDifference;
          const yearlyYield = Math.pow(holdingPeriodYield + 1, pow) - 1;
          const yearlyYieldPercent = yearlyYield * 100;
          return <span>{yearlyYieldPercent.toFixed(2)}%</span>;
        }
      },
      width: "15%",
    },
  ];

  return (
    <div style={{ margin: 10 }}>
      {transactionList.length > 0 && (
        <Card className="mb-2">
          <div className="lg:flex gap-2 justify-center items-center">
            <InvestReturnChart
              data={transactionList.reverse()}
            />
            <div className="text-xl">
              <div>
                Таны цалингаас хуримтлуулсан дүн: <strong>{numberWithCommas(transactionList[0].cumulativeSalaryAmount)}₮</strong>
              </div>
              <div>
                Таны байгууллагаас хуримтлуулсан дүн: <strong>{numberWithCommas(transactionList[0].cumulativeCompanyAmount)}₮</strong>
              </div>
              <div>
                Таны нийт хуримтлалын өссөн дүн: <strong>{
                  numberWithCommas(new BigNumber(transactionList[0].balanceEmployeeSecurities + transactionList[0].balancePoolSecurity)
                    .times(nav)
                    .toNumber())
                }₮</strong>
              </div>
            </div>
          </div>
        </Card>
      )}

      <Table
        scroll={{ x: true }}
        dataSource={transactionList.reverse()}
        columns={columns}
        loading={loading}
        rowKey="id"
        pagination={{
          defaultPageSize: 50,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50, 100],
        }}
        footer={() => footerContent}
      />

    </div>
  );
};
export default TransactionList;
