import { Button, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ITransactionDetail } from "../../interfaces";
import { numberWithCommas } from "../../utils";
import { useEffect } from "react";
import { getNav } from "../../features/nav/navActions";
import { apiClient } from "../../api";
import { useParams } from "react-router-dom";

const getColumns = (nav: number): ColumnsType<ITransactionDetail> => [
  {
    title: "Огноо",
    dataIndex: "createdAt",
    render: (date: string, record) => (
      <a href={`/company/transactions/${record.transactionId}`}>
        <span title={dayjs(date).format("YYYY-MM-DD HH:mm:ss")}>
          {dayjs(date).format("YYYY-MM-DD")}
        </span>
      </a>
    ),
    sorter: {
      compare: (a: ITransactionDetail, b: ITransactionDetail) =>
        a.createdAt > b.createdAt ? 1 : -1,
      multiple: 1,
    },
    defaultSortOrder: "descend",
  },
  {
    title: "Тайлбар",
    dataIndex: "description",
  },
  {
    title: "Төлөв",
    dataIndex: "status",
  },
  {
    title: "Ажилтнаас",
    dataIndex: "employeeSecurities",
    render: (digit: number, record) => numberWithCommas(digit * record.nav),
  },
  {
    title: "Байгууллагаас",
    dataIndex: "poolSecurity",
    render: (digit: number, record) => numberWithCommas(digit * record.nav),
  },
  {
    title: "Нийт хуримтлал",
    render: (_, record) =>
      numberWithCommas(
        (record.balanceEmployeeSecurities + record.balancePoolSecurity) *
        record.nav,
      ),
  },
  {
    title: "Нийт үр дүн",
    render: (_, record) => {
      return (
        <div>
          <div>
            {numberWithCommas(
              (record.balanceEmployeeSecurities + record.balancePoolSecurity) *
              nav,
            )}
          </div>
          <div>
            Өсөлт:{" "}
            {numberWithCommas(
              (record.balanceEmployeeSecurities + record.balancePoolSecurity) *
              nav -
              (record.balanceEmployeeSecurities +
                record.balancePoolSecurity) *
              record.nav,
            )}
          </div>
        </div>
      );
    },
  },
];

const Transactions = () => {
  let { id } = useParams();
  const { data } = useAppSelector((state) => state.employee);
  const { nav } = useAppSelector((state) => state.nav);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getNav());
  }, []);

  const downloadExcel = async () => {
    try {
      const response = await apiClient.get(`/employee/excel/${id}`, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/pdf'
        }
      })
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'employee_roi.xlsx';
      a.click();
    } catch (err) {

    }
  }

  return (
    <>
    <Button onClick={downloadExcel} className="mb-2">Экселээр авах</Button>
    <Table
      dataSource={data?.transactionDetail}
      rowKey="id"
      columns={getColumns(nav)}
      pagination={{
        defaultPageSize: 50,
        showSizeChanger: true,
        pageSizeOptions: [50, 100, 200],
      }}
    />
    </>
  );
};

export default Transactions;
