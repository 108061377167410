import { Tag } from "antd";

export const EmployeeChip = ({status}: {status: string}) => {
  return (
    <Tag
      color={status === "confirmed" ? "green" :
        status === "deleted" ? "red" : "orange"}
      
    >
      {status === "confirmed" ? "Хуримтлуулагч" :
        status === "deleted" ? "Ажлаас гарсан" : "Хүлээгдэж буй"}
    </Tag>
  );
}

export const TransactionChip = ({status}: {status: string}) => {
  return (
    <Tag
      color={status === "confirmed" ? "green" : "orange"}
      
    >
      {status === "confirmed" ? "Баталгаажсан" : "Хүлээгдэж буй"}
    </Tag>
  );
}