import React from "react";
import { Card } from "antd";
import dayjs from "dayjs";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { ITransactionDetail } from "../../interfaces";

interface IProps {
  data: ITransactionDetail[];
}

const InvestReturnChart: React.FC<IProps> = ({
  data,
}) => {
  const renderTooltipContent = (props: any) => {
    const { active, payload, label } = props;

    if (active && payload && payload.length) {
      const roi = payload.find(
        (entry: any) => entry.dataKey === "return",
      );
      const salaryAmount = payload.find(
        (entry: any) => entry.dataKey === "cumulativeSalaryAmount",
      );
      const companyAmount = payload.find(
        (entry: any) => entry.dataKey === "cumulativeCompanyAmount",
      );

      return (
        <div
          className="bg-white p-4 w-72"
          style={{ border: "1px solid #c9b7b7" }}
        >
          <p>{`${dayjs(label).format("YYYY")} онд:`}</p>
          <table className="w-full border-2 border-black">
            <tbody>
              <tr>
                <td className="py-1 flex">
                  <div className="h-2 w-2 mt-2 mr-2 rounded bg-chartColorA"></div>
                  Ажилтнаас:
                </td>
                <td className="py-1">
                  {salaryAmount &&
                    `${Math.floor(salaryAmount.value).toLocaleString()}`}
                </td>
              </tr>
              <tr>
                <td className="py-1 flex">
                  <div className="h-2 w-2 mt-2 mr-2 rounded bg-chartColorA"></div>
                  Компаниас:
                </td>
                <td className="py-1">
                  {companyAmount &&
                    `${Math.floor(companyAmount.value).toLocaleString()}`}
                </td>
              </tr>
              <tr>
                <td className="py-1 flex">
                  <div className="h-2 w-2 mt-2 mr-2 rounded bg-chartColorB"></div>
                  Хуримтлалын хүч:{" "}
                </td>
                <td className="py-1">
                  {roi &&
                    `${Math.floor(roi.value).toLocaleString()}`}
                </td>
              </tr>
              <tr>
                <td>Үр дүн: </td>
                <td className="py-1 font-bold">
                  {roi &&
                    `${Math.floor(roi.value + salaryAmount.value + companyAmount.value).toLocaleString()}`}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="mt-8 h-[400px] ml-4 flex">
      <BarChart
        width={700}
        height={400}
        data={data}
        margin={{ top: 20, right: 30, left: 40, bottom: 5 }}
        barSize={20}
      >
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis
          dataKey="confirmedAt"
          tickFormatter={(value: any) => dayjs(value).format("YYYY-MM")}
        />
        <YAxis
          tickFormatter={(value: any) => Math.floor(value).toLocaleString()}
        />
        <Tooltip content={renderTooltipContent} />
        <Legend />
        <Bar
          dataKey="cumulativeSalaryAmount"
          name="Ажилтанаас"
          stackId="a"
          fill="#57B7EF"
        />
        <Bar
          dataKey="cumulativeCompanyAmount"
          name="Компаниас"
          stackId="a"
          fill="#53BA6C"
        />
        <Bar
          dataKey="return"
          name="Хуримталын хүч"
          stackId="a"
          fill="#F9B719"
        />
      </BarChart>
    </div>
  );
};

export default InvestReturnChart;
